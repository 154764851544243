<template>
  <div
    class="popup bg-b-lue"
    v-on:click.self="isLoading ? null : hideOrderDetailsModal()"
  >
    <div v-if="isLoading">
      <LoaderAnimation />
      <div
        style="
          font-size: 14px;
          margin-top: 30px;
          color: #2e5ba7;
          font-weight: 600;
        "
      >
        Please wait
      </div>
    </div>
    <div class="modal" v-else>
      <div
        style="
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: flex-start;
          top: 0;
        "
      >
        <div class="frame-35654">
          <div class="info-wrapper">
            <div class="frame-35656">
              <div class="order-status">Order Details</div>
              <div class="frame-35655">
                <div class="company-name">Company Name</div>
                <div class="yoki-biodiesel-private-limited">
                  {{ searchResults?.name.toLowerCase() }}
                </div>
              </div>
              <div class="frame-35658">
                <div class="country">Country</div>
                <div class="india">{{ searchResults?.country }}</div>
              </div>
              <div class="frame-35662">
                <div class="classification">Report Type</div>
                <div class="private-limited-company">
                  {{ searchResults?.type.toLowerCase() }}
                </div>
              </div>
              <!-- <div class="frame-35662">
              <div class="classification">Order Status</div>
              <div class="private-limited-company">
                {{ searchResults?.status !== undefined ? searchResults?.status.toLowerCase() : "Delivered" }}
              </div>
            </div> -->
            </div>
            <div class="frame-35854">
              <div class="order-status">Order Status</div>
              <div class="frame-35853">
                <div
                  class="status3"
                  :style="{
                    background:
                      searchResults?.status === 'Order Received' ||
                      searchResults?.status === 'PENDING'
                        ? '#26bbc2'
                        : '#ecf4ff',
                    opacity:
                      searchResults?.status === 'Order Received' ||
                      searchResults?.status === 'PENDING'
                        ? 1
                        : 0.5,
                  }"
                >
                  <img
                    class="order-confirmed3"
                    v-if="
                      searchResults?.status === 'Order Received' ||
                      searchResults?.status === 'PENDING'
                    "
                    :src="this.$root.getCDNlink('assets/1st.gif')"
                  />
                  <img class="order-confirmed3" v-else :src="this.$root.getCDNlink('assets/1st.png')" />
                  <div class="fresh-data-procured">Order RECIEVED</div>
                  <IconsProperty1Tick
                    property1="tick"
                    class="icons-instance"
                  ></IconsProperty1Tick>
                </div>
                <svg
                  width="12"
                  height="25"
                  viewBox="0 0 12 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 24.4167L11.7735 14.4167L0.226497 14.4167L6 24.4167ZM5 0.416748L5 3.41675L7 3.41675L7 0.416748L5 0.416748ZM5 9.41675L5 15.4167L7 15.4167L7 9.41675L5 9.41675Z"
                    fill="#11365A"
                    fill-opacity="0.84"
                  />
                </svg>
                <div
                  class="status3"
                  :style="{
                    background:
                      searchResults?.status === 'Order Confirmed'
                        ? '#26bbc2'
                        : '#ecf4ff',
                    opacity:
                      searchResults?.status === 'Order Confirmed' ? 1 : 0.5,
                  }"
                >
                  <img
                    class="order-confirmed3"
                    v-if="searchResults?.status === 'Order Confirmed'"
                    :src="this.$root.getCDNlink('assets/2nd.gif')"
                  />
                  <img class="order-confirmed3" v-else :src="this.$root.getCDNlink('assets/2nd.png')" />
                  <div class="fresh-data-procured">Order CONFIRMED</div>
                  <IconsProperty1Tick
                    property1="tick"
                    class="icons-instance2"
                  ></IconsProperty1Tick>
                </div>
                <svg
                  width="12"
                  height="25"
                  viewBox="0 0 12 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 24.4167L11.7735 14.4167L0.226497 14.4167L6 24.4167ZM5 0.416748L5 3.41675L7 3.41675L7 0.416748L5 0.416748ZM5 9.41675L5 15.4167L7 15.4167L7 9.41675L5 9.41675Z"
                    fill="#11365A"
                    fill-opacity="0.84"
                  />
                </svg>
                <div
                  class="status3"
                  :style="{
                    background:
                      searchResults?.status === 'Data Procured'
                        ? '#26bbc2'
                        : '#ecf4ff',
                    opacity:
                      searchResults?.status === 'Data Procured' ? 1 : 0.5,
                  }"
                >
                  <img
                    class="order-confirmed3"
                    v-if="searchResults?.status === 'Data Procured'"
                    :src="this.$root.getCDNlink('assets/3rd.gif')"
                  />
                  <img class="order-confirmed3" v-else :src="this.$root.getCDNlink('assets/3rd.png')" />
                  <div class="fresh-data-procured">FRESH DATA PROCURED</div>
                </div>
                <svg
                  width="12"
                  height="25"
                  viewBox="0 0 12 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 24.4167L11.7735 14.4167L0.226497 14.4167L6 24.4167ZM5 0.416748L5 3.41675L7 3.41675L7 0.416748L5 0.416748ZM5 9.41675L5 15.4167L7 15.4167L7 9.41675L5 9.41675Z"
                    fill="#11365A"
                    fill-opacity="0.84"
                  />
                </svg>
                <div
                  class="status3"
                  :style="{
                    background:
                      searchResults?.status === 'Report with First Checker'
                        ? '#26bbc2'
                        : '#ecf4ff',
                    opacity:
                      searchResults?.status === 'Report with First Checker'
                        ? 1
                        : 0.5,
                  }"
                >
                  <img
                    class="order-confirmed3"
                    v-if="searchResults?.status === 'Report with First Checker'"
                    :src="this.$root.getCDNlink('assets/4th.gif')"
                  />
                  <img class="order-confirmed3" v-else :src="this.$root.getCDNlink('assets/4th.png')" />
                  <div class="report-with-first-checker">
                    REPORT WITH FIRST CHECKER
                  </div>
                </div>
                <svg
                  width="12"
                  height="25"
                  viewBox="0 0 12 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 24.4167L11.7735 14.4167L0.226497 14.4167L6 24.4167ZM5 0.416748L5 3.41675L7 3.41675L7 0.416748L5 0.416748ZM5 9.41675L5 15.4167L7 15.4167L7 9.41675L5 9.41675Z"
                    fill="#11365A"
                    fill-opacity="0.84"
                  />
                </svg>
                <!-- <div class="status3" :style="{ background: searchResults?.status === 'Prop model runs' ? '#26bbc2' : '#ecf4ff', opacity: searchResults?.status === 'Prop model runs' ? 1 : 0.5 }">
                <img class="order-confirmed3" src="@/assets/5th.gif" />
                <div class="prop-model-runs">PROP MODEL RUNS</div>
              </div>
              <svg
                width="12"
                height="25"
                viewBox="0 0 12 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 24.4167L11.7735 14.4167L0.226497 14.4167L6 24.4167ZM5 0.416748L5 3.41675L7 3.41675L7 0.416748L5 0.416748ZM5 9.41675L5 15.4167L7 15.4167L7 9.41675L5 9.41675Z"
                  fill="#11365A"
                  fill-opacity="0.84"
                />
              </svg> -->
                <div
                  class="status3"
                  :style="{
                    background:
                      searchResults?.status === '2nd Level check'
                        ? '#26bbc2'
                        : '#ecf4ff',
                    opacity:
                      searchResults?.status === '2nd Level check' ? 1 : 0.5,
                  }"
                >
                  <img
                    class="order-confirmed3"
                    v-if="searchResults?.status === '2nd Level check'"
                    :src="this.$root.getCDNlink('assets/6th.gif')"
                  />
                  <img class="order-confirmed3" v-else :src="this.$root.getCDNlink('assets/6th.png')" />
                  <div class="_2nd-level-check">2ND LEVEL CHECK</div>
                </div>
                <svg
                  width="12"
                  height="25"
                  viewBox="0 0 12 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 24.4167L11.7735 14.4167L0.226497 14.4167L6 24.4167ZM5 0.416748L5 3.41675L7 3.41675L7 0.416748L5 0.416748ZM5 9.41675L5 15.4167L7 15.4167L7 9.41675L5 9.41675Z"
                    fill="#11365A"
                    fill-opacity="0.84"
                  />
                </svg>
                <div
                  class="status3"
                  :style="{
                    background:
                      searchResults?.status === 'Delivery Date'
                        ? '#26bbc2'
                        : '#ecf4ff',
                    opacity:
                      searchResults?.status === 'Delivery Date' ? 1 : 0.5,
                  }"
                >
                  <img
                    class="order-confirmed3"
                    v-if="searchResults?.status === 'Delivery Date'"
                    :src="this.$root.getCDNlink('assets/7th.gif')"
                  />
                  <img class="order-confirmed3" v-else :src="this.$root.getCDNlink('assets/7th.png')" />
                  <div class="delivery-by-01st-april-2024">
                    DELIVERY BY {{ formattedDeliveryBy() }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="close-button"
            v-on:click.prevent="hideOrderDetailsModal()"
          >
            Close
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
// import { CreateOrder, AllCountries, AllCurrencies } from "@/api/APIs";
//   import VueCookies from "vue-cookies";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";
import Toastify from "@/components/Toastify.vue";

export default {
  name: "OrderDetailsModal",
  components: {
    LoaderAnimation,
    Toastify,
  },
  props: {
    hideOrderDetailsModal: {
      type: Function,
      required: true,
    },
    searchResults: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    formattedDeliveryBy() {
      const date = new Date(this.searchResults.deliveryBy);
      const day = date.getUTCDate().toString().padStart(2, "0");
      const month = date.toLocaleString("default", {
        month: "short",
        timeZone: "UTC",
      });
      const year = date.getUTCFullYear();
      // const hours = date.getUTCHours();
      // const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      // const period = hours >= 12 ? "PM" : "AM";
      // const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
      return `${day} ${month} ${year}`;
    },
    closeResultsOnClickOutside(event) {
      const searchInput = this.$refs.searchInput;
      const countryResults = this.$refs.countryResults;
      if (
        searchInput &&
        !searchInput.contains(event.target) &&
        countryResults &&
        !countryResults.contains(event.target)
      ) {
        this.showResultsFlag = false;
        document.removeEventListener("click", this.closeResultsOnClickOutside);
      }
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.flag-image {
  width: 20px;
  height: 15px;
}
.modal {
  background: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  overflow: hidden;
  margin-top: 4rem;
  margin-bottom: 4rem;
  width: 70%;
  /* height: 95%; */
}
.your-order-details-here {
  color: var(--dark-blue, #11365a);
  margin-bottom: 35px;
  text-align: left;
  padding: 10px 0;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #11365a;
}
.frame-35654 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 60px 60px 40px 60px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.info-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35656 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  position: relative;
  width: 40%;
  overflow-wrap: anywhere;
}
.frame-35655 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.company-name {
  color: var(--text-dark, #04162d);
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
  align-self: stretch;
}
.yoki-biodiesel-private-limited {
  color: var(--text-mid, #3e4855);
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  align-self: stretch;
  text-transform: capitalize;
}
.frame-356562 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.report-tyoe {
  color: var(--text-dark, #04162d);
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
  align-self: stretch;
}
.fresh-report {
  color: var(--text-mid, #3e4855);
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  align-self: stretch;
}
.frame-35657 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.delivery-time {
  color: var(--text-dark, #04162d);
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
  align-self: stretch;
}
._8-hours {
  color: var(--text-mid, #3e4855);
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  align-self: stretch;
}
.frame-35658 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.country {
  color: var(--text-dark, #04162d);
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
  align-self: stretch;
}
.india {
  color: var(--text-mid, #3e4855);
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  align-self: stretch;
}
.frame-35659 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.cin-identifiers {
  color: var(--text-dark, #04162d);
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
  align-self: stretch;
}
.u-24299-mh-2021-ptv-372774 {
  color: var(--text-mid, #3e4855);
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  align-self: stretch;
}
.frame-356572 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;
}
.frame-35660 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.status {
  color: var(--text-dark, #04162d);
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
  align-self: stretch;
}
.active {
  color: var(--text-mid, #3e4855);
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  align-self: stretch;
}
.frame-35661 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.is-listed {
  color: var(--text-dark, #04162d);
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
  align-self: stretch;
}
.false {
  color: var(--text-mid, #3e4855);
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  align-self: stretch;
}
.frame-35663 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.year-of-incorporation {
  color: var(--text-dark, #04162d);
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
  align-self: stretch;
}
._2021 {
  color: var(--text-mid, #3e4855);
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  align-self: stretch;
}
.frame-35662 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.classification {
  color: var(--text-dark, #04162d);
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
  align-self: stretch;
}
.private-limited-company {
  color: var(--text-mid, #3e4855);
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  align-self: stretch;
  text-transform: capitalize;
}
.frame-35653 {
  background: #ceeaeb;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 469px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18),
    -4px -4px 8px 0px rgba(255, 255, 255, 1);
  position: relative;
}
.frame-35652 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35627 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.select-your-report {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
}
.frame-35628 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35664 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.in-25-min {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Italic", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  font-style: true;
  position: relative;
}
.frame-35629 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.in-4-hrs {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Italic", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  font-style: true;
  position: relative;
}
.frame-35630 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.in-8-hrs {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Italic", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  font-style: true;
  position: relative;
}
.frame-35647 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 2rem;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.button-instance {
  background: var(--main-color, #26bbc2) !important;
  padding: 17px 24px 17px 24px !important;
  align-self: stretch !important;
}
.button-instance2 {
  padding: 17px 24px 17px 24px !important;
  align-self: stretch !important;
}
.ref-wrapper {
  display: flex;
  flex-direction: column;
  width: 49.5%;
  gap: 10px;
}
.frame-3 {
  background: #ffffff;
  border-radius: 16px !important;
  border: 1px solid rgba(87, 87, 87, 0.5);
  display: flex;
  flex-direction: row;
  gap: 100px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.enter-email-address {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  /* font-size: 14px; */
  font-weight: 400;
  position: relative;
  border-radius: 13px;
  border: none;
  outline: none;
  padding: 10px 17px 10px 17px;
  width: 100%;
  height: auto;
  box-shadow: none;
  margin: 0;
  cursor: pointer;
}
.rectangle-10 {
  background: #ffffff;
  border-radius: 4px;
  border-style: solid;
  border-color: #18c3df;
  border-width: 2px;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  position: relative;
  cursor: pointer;
}
.remember-me {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
}
.remember-me {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
}
.order-button {
  border-radius: 13px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 150px;
  width: 47%;
  cursor: pointer;
}
.button-text {
  color: #ffffff;
  text-align: center;
  font-family: "NunitoSans-SemiBold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}
.input-group {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.input-group input,
.input-group select,
.input-group textarea {
  padding: 10px;
  border: 1px solid rgba(87, 87, 87, 0.5);
  border-radius: 13px;
  height: auto !important;
  box-shadow: none;
  margin: 0;
  width: 100%;
  outline: none;
}
.country-results {
  width: 100%;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background-color: white;
  border-radius: 15px;
  overflow-y: auto;
  top: 100%;
  z-index: 9999;
  box-shadow: 0px 16px 16px 0px rgba(20, 45, 77, 0.1);
}
.country-results div {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding: 5px 0 5px 20px;
  text-align: left;
  font-size: 14px;
}
.country-results div:hover {
  background-color: #dee9f2;
}
.user-details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.country-dropdown {
  width: 35% !important;
  outline: none;
  border: none !important;
}
.deliver-to {
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: left;
  height: 24px;
}
.order-total {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.USD-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}
.USD {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat-Regular", sans-serif;
}
.currency-title {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat-Regular", sans-serif;
}
.all-currency-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  gap: 15px;
}
.currency-dropdown {
  width: 22% !important;
  outline: none;
  border: 1px solid rgba(87, 87, 87, 0.5);
  border-radius: 13px;
  padding: 10px;
}

.frame-35854 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.order-status {
  color: var(--dark-blue, rgba(17, 54, 90, 0.84));
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.frame-35853 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex: 1;
  position: relative;
  gap: 2px;
}
.status {
  background: #f4f9ff;
  border-radius: 16px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25),
    -4px -4px 8px 0px rgba(255, 255, 255, 1);
}
.order-confirmed {
  border-radius: 4px;
  opacity: 0.4000000059604645;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
  object-fit: cover;
}
.order-recieved {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.4000000059604645;
  position: relative;
}
.icons-instance {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  right: 18px !important;
  top: 18px !important;
}
.line-23 {
  flex-shrink: 0;
  width: 24px;
  height: 0px;
  position: relative;
  transform: translate(-5.77px, 0px);
  overflow: visible;
}
.order-confirmed2 {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.4000000059604645;
  position: relative;
}
.icons-instance2 {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  right: 18px !important;
  top: 18px !important;
}
.line-24 {
  flex-shrink: 0;
  width: 24px;
  height: 0px;
  position: relative;
  transform: translate(-5.77px, 0px);
  overflow: visible;
}
.status2 {
  background: linear-gradient(to left, #26bbc2, #26bbc2),
    linear-gradient(to left, #e7ebf0, #e7ebf0);
  border-radius: 16px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25),
    -4px -4px 8px 0px rgba(255, 255, 255, 1);
}
.order-confirmed3 {
  border-radius: 4px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
  object-fit: cover;
}
.fresh-data-procured {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.line-25 {
  flex-shrink: 0;
  width: 24px;
  height: 0px;
  position: relative;
  transform: translate(-5.77px, 0px);
  overflow: visible;
}
.status3 {
  background: #ecf4ff;
  border-radius: 16px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25),
    -4px -4px 8px 0px rgba(255, 255, 255, 1);
}
.report-with-first-checker {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.line-26 {
  flex-shrink: 0;
  width: 24px;
  height: 0px;
  position: relative;
  transform: translate(-5.77px, 0px);
  overflow: visible;
}
.prop-model-runs {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.line-27 {
  flex-shrink: 0;
  width: 24px;
  height: 0px;
  position: relative;
  transform: translate(-5.77px, 0px);
  overflow: visible;
}
._2nd-level-check {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.line-28 {
  flex-shrink: 0;
  width: 24px;
  height: 0px;
  position: relative;
  transform: translate(-5.77px, 0px);
  overflow: visible;
}
.delivery-by-01st-april-2024 {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.close-button {
  width: 100%;
  border-radius: 13px;
  border: 2px solid #11365a;
  font-size: 16px;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-weight: 600;
  text-align: center;
  padding: 15px 0;
}
@media only screen and (max-width: 767px) {
  .info-wrapper {
    flex-direction: column;
  }
  .modal {
    height: 90%;
    width: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .frame-35654 {
    /* height: 100%; */
    padding: 3vh 8vw;
    /* align-self: stretch; */
  }
  .order-status {
    font-size: 18px;
  }
  .frame-35656 {
    gap: 15px;
    width: -webkit-fill-available;
  }
  .fresh-data-procured, .report-with-first-checker, ._2nd-level-check, .delivery-by-01st-april-2024 {
    font-size: 14px;
  }
  .order-confirmed3 {
    width: 25px;
    height: 25px;
  }
  svg:not(:root) {
    height: 20px;
  }
  .frame-35654 {
    gap: 30px;
  }
  .close-button {
    padding: 8px 0;
  }
  .status3 {
    padding: 8px 16px 8px 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
}
@media only screen and (min-width: 1156px) {
}
</style>
