<template>
  <div class="popup bg-b-lue" v-on:click.self="close()">
    <div class="frame-35628">
      <div class="title">Invitation Status</div>
      <div class="invitees-wrapper">
        <div class="invitees-list invitees-list-height">
          <div class="table-wrapper" v-if="invitees && invitees?.length !== 0">
            <table
              id="searchResultTable"
              v-if="invitees && invitees.length > 0"
            >
              <thead style="background: #ecf4ff" v-if="!this.$root.mobile">
                <tr>
                  <th v-for="col in tableColumns" :key="col">
                    {{ col }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(invitee, index) in invitees"
                  :style="hoveredRow2 === index ? hoverStyle : {}"
                  :key="index"
                >
                  <td>
                    <p>
                      <strong v-if="this.$root.mobile">Email: </strong
                      >{{ invitee?.email }}
                    </p>
                    <p v-if="this.$root.mobile">
                      <strong style="margin-right: 10px">Status: </strong>
                      <i
                        v-if="invitee?.status === true"
                        class="fa-solid fa-check"
                        style="color: green; font-size: large"
                      ></i>
                      <i
                        v-else
                        class="fa-solid fa-xmark"
                        style="color: #d00202; font-size: large"
                      ></i>
                    </p>
                    <p v-if="this.$root.mobile">
                      <strong>Message: </strong>
                      <span>{{ invitee.statusMessage }}</span>
                    </p>
                  </td>
                  <td v-if="!this.$root.mobile">
                    <i
                      v-if="invitee?.status === true"
                      class="fa-solid fa-check"
                      style="color: green; font-size: large"
                    ></i>
                    <i
                      v-else
                      class="fa-solid fa-xmark"
                      style="color: #d00202; font-size: large"
                    ></i>
                    <span style="padding-left: 10px">{{
                      invitee.statusMessage
                    }}</span>
                  </td>
                  <td>
                    <img
                      :src="this.$root.getCDNlink('assets/Icons/helpdesk.webp')"
                      alt="Credence Helpdesk"
                      class="icon-image"
                      v-if="invitee?.status === false"
                      style="
                        width: 25px;
                        height: 25px;
                        align-self: center;
                        margin-top: 5px;
                        cursor: pointer;
                      "
                      @click="supportRequest(invitee)"
                    />
                    <strong v-else style="margin-left: 9px">-</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="no-invitees" v-else>
            <AddTeamMembers />
            No data found
          </div>
        </div>
        <div class="cancel-button" @click="close()">
          <span>Close</span>
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import VueCookies from "vue-cookies";
import Toastify from "@/components/Toastify.vue";
import AddTeamMembers from "@/assets/Animation/AddTeamMembers/AddTeamMembers.vue";
import { ContactUs } from "@/api/APIs";

export default {
  name: "InviteMembersModal",
  props: {
    invitations: {
      type: Array,
      required: true,
    },
  },
  components: {
    Toastify,
    AddTeamMembers,
  },
  data() {
    return {
      tableColumns: ["Invited user", "Invitation sent status", "Action"],
      invitees: this.invitations,
    };
  },
  mounted() {},
  methods: {
    close() {
      this.invitees = null;
      this.$emit("close");
    },
    async supportRequest(object) {
      const payload = {
        businessEmail: VueCookies.get("email"),
        errorObj: object,
      };
      try {
        const response = await ContactUs(payload);
        if (response.message == "Mail sent to customer support!") {
          return this.$refs.toastContainer.addToast(
            `Request submitted. Support team will contact you soon`,
            { type: "success" }
          );
        } else {
          return this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: flex-start;
  width: 60vw;
  position: relative;
  background: #ffffff;
  border-radius: 24px;
  min-height: 384px;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.title {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
  text-align: left;
}
.invitees-wrapper,
.invitees-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  gap: 18px;
}
.invitees-list-height {
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f2f6fd;
  padding: 10px;
  border-radius: 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.remove-invitee {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  cursor: pointer;
}
.create-button {
  display: flex;
  color: white;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  background: #11365a;
  cursor: pointer;
  align-self: stretch;
}
.create-button.disabled {
  opacity: 0.4;
  background: var(--Dark-Blue, #11365a);
}
.cancel-button,
.add-button {
  color: #11365a;
  border: 2px solid #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: stretch;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin: 20px 0; */
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  padding: 10px 16px;
  min-width: 30px;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.no-invitees {
  align-self: stretch;
  color: #11365a5d;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .frame-35628 {
    width: 95vw;
    padding: 1.5rem 1.2rem;
    min-height: auto;
  }
  .title {
    font-size: 18px;
  }
  .invitees-list-height {
    height: 30vh;
  }
  .add-button {
    padding: 6px 24px;
  }
  .create-button {
    padding: 8px 24px;
  }
  .cancel-button {
    padding: 6px 24px;
  }
  #searchResultTable th,
  #searchResultTable td {
    font-size: 14px;
    padding: 5px 10px;
  }
  #searchResultTable tr {
    border-radius: 13px;
    border: 1px solid rgb(236, 236, 236);
    margin-bottom: 7px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
}
@media only screen and (min-width: 1156px) {
  .invitee-email {
    width: 40%;
  }
  .add-button {
    width: 18%;
  }
}
</style>
