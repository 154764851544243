<template>
  <div class="about-us-v-2">
    <!-- <NavbarMobileNo class="navbar-instance"></NavbarMobileNo> -->
    <div class="hero-section">
      <section class="BlogsBanner" :style="{
          backgroundImage: `url(${this.$root.getCDNlink(
            'assets/AboutUs/Banner.jpg'
          )})`,
        }">
        <div class="BlogsBanner_after container-fluid">
          <div class="BlogbannercontentBox">
            <span class="TitleTop">About Us</span>
            <div
              class="bridging-gaps-unveiling-opportunities-actionable-global-business-intelligence-for-cross-border-trade"
            >
              Bridging Gaps &amp; Unveiling Opportunities:
              <br />
              Actionable Global Business Intelligence for Cross-Border Trade
            </div>
          </div>
        </div>
      </section>
      <div class="frame-358362">
        <img :src="this.$root.getCDNlink('assets/Team.jpeg')" class="rectangle-112-img" />
        <div class="frame-35836">
          <div class="our-story">Our Story</div>
          <div class="the-data-story-for-asia">The Data story for Asia</div>
          <div
            class="more-than-a-decade-ago-we-embarked-on-a-mission-unlike-any-other-connecting-not-just-large-corporates-but-the-smallest-of-sm-es-in-emerging-countries-with-the-global-financial-ecosystem-faced-with-a-landscape-where-existing-solutions-failed-to-offer-the-breadth-and-depth-of-information-necessary-we-forged-our-path-credence-was-born-in-2022-out-of-a-need-for-comprehensive-insights-into-the-risks-associated-with-exports-and-imports-today-we-are-proud-to-be-the-trusted-partner-for-banks-corporates-of-various-sizes-providing-expedited-and-updated-risk-assessments-that-empower-cross-border-trade"
          >
            More than a decade ago, we embarked on a mission unlike any
            other—connecting not just large corporates but the smallest of SMEs
            in emerging countries with the global financial ecosystem. Faced
            with a landscape where existing solutions failed to offer the
            breadth and depth of information necessary, we forged our path.
            Credence was born in 2022 out of a need for comprehensive insights
            into the risks associated with exports and imports. Today, we are
            proud to be the trusted partner for banks &amp; corporates of
            various sizes, providing expedited and updated risk assessments that
            empower cross-border trade.
          </div>
        </div>
      </div>
      <div class="frame-35839">
        <div class="frame-358363">
          <div class="stories-of-success">Stories of Success</div>
          <div class="making-an-impact">Making an Impact</div>
          <div
            class="our-work-speaks-through-the-successes-of-the-businesses-we-ve-partnered-with-from-sm-es-in-remote-corners-of-the-world-gaining-access-to-global-markets-to-major-banks-streamlining-their-risk-assessment-processes-our-impact-is-as-varied-as-it-is-profound-these-stories-our-badges-of-honor-exemplify-the-transformative-power-of-accessible-reliable-business-intelligence"
          >
            Our work speaks through the successes of the businesses we&#039;ve
            partnered with. From SMEs in remote corners of the world gaining
            access to global markets, to major Banks streamlining their risk
            assessment processes, our impact is as varied as it is profound.
            These stories, our badges of honor, exemplify the transformative
            power of accessible, reliable business intelligence.
          </div>
        </div>
      </div>
      <div class="frame-35837">
        <div class="frame-35836">
          <div class="our-mission">Our MISSION</div>
          <div class="accelerating-borderless-trade-for-everyone">
            Accelerating Borderless Trade for everyone
          </div>
          <div
            class="at-credence-we-re-more-than-a-business-intelligence-company-we-re-a-catalyst-for-change-in-b-2-b-transactions-across-diverse-geographies-our-core-belief-a-democratized-world-of-trade-where-the-size-of-your-enterprise-or-its-location-doesn-t-dictate-your-success-whether-you-re-buying-selling-or-financing-we-re-here-to-ensure-you-make-the-right-decision-every-time-we-envision-a-world-where-every-business-irrespective-of-its-scale-or-geography-can-compete-on-a-level-playing-field"
          >
            At Credence, we’re more than a business intelligence company. We’re
            a catalyst for change in B2B transactions across diverse
            geographies. Our core belief? A democratized world of trade where
            the size of your enterprise or its location doesn’t dictate your
            success. Whether you’re buying, selling, or financing, we’re here to
            ensure you make the right decision, every time. We envision a world
            where every business, irrespective of its scale or geography, can
            compete on a level playing field.
          </div>
        </div>
        <img
          :src="this.$root.getCDNlink('assets/AboutUs/DataStory.jpg')"
          style="max-width: none"
          class="rectangle-112-img"
        />
      </div>
      <div class="frame-35838">
        <div class="frame-35836">
          <div class="our-team">Our TEAM</div>
          <div class="the-heart-behind-the-mission">
            The Heart Behind the Mission
          </div>
          <div
            class="in-hong-kong-india-and-bangladesh-our-journey-would-not-be-possible-without-our-dedicated-team-spanning-across-asia-our-team-comprises-individuals-passionate-about-breaking-down-the-barriers-to-global-trade-and-with-background-in-international-trade-and-finance-from-risk-analysts-to-customer-success-specialists-every-member-of-our-team-is-dedicated-to-empowering-our-clients-with-actionable-intelligence-we-believe-in-the-power-of-diversity-not-just-in-the-markets-we-serve-but-within-our-own-ranks-this-diversity-fuels-our-innovation-enabling-us-to-understand-and-meet-the-needs-of-a-varied-clientele-our-team-s-expertise-is-matched-only-by-their-dedication-to-our-mission-of-democratizing-global-trade-finance"
          >
            In Hong Kong, India and Bangladesh, Our journey would not be possible without our dedicated team,
            spanning across Asia. Our team comprises individuals passionate
            about breaking down the barriers to global trade and with background
            in International Trade and Finance. From risk analysts to customer
            success specialists, every member of our team is dedicated to
            empowering our clients with actionable intelligence.
            <br />
            <br />
            We believe in the power of diversity, not just in the markets we
            serve but within our own ranks. This diversity fuels our innovation,
            enabling us to understand and meet the needs of a varied clientele.
            Our team&#039;s expertise is matched only by their dedication to our
            mission of democratizing global trade finance.
          </div>
        </div>
        <img
          :src="this.$root.getCDNlink('assets/AboutUs/OurStory.png')"
          style="max-width: none"
          class="rectangle-112-img"
        />
      </div>
      <div class="frame-35840">
        <div class="frame-358363">
          <div class="join-us-on-this-journey">Join Us on This Journey</div>
          <div
            class="we-re-on-a-mission-to-redefine-the-landscape-of-global-trade-making-it-accessible-transparent-and-fair-for-all-if-you-re-ready-to-explore-how-credence-can-empower-your-business-contact-us-today-let-s-unveil-opportunities-together"
          >
            We&#039;re on a mission to redefine the landscape of global trade,
            making it accessible, transparent, and fair for all. If you&#039;re
            ready to explore how Credence can empower your business, contact us
            today. Let&#039;s unveil opportunities together.
          </div>
        </div>
      </div>
    </div>
    <Footer class="footer-instance"></Footer>
    <div v-show="showScrollToTop" class="scroll-to-top" @click="scrollToTop">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0306 9.53073C16.9609 9.60046 16.8782 9.65578 16.7871 9.69352C16.6961 9.73127 16.5985 9.75069 16.4999 9.75069C16.4014 9.75069 16.3038 9.73127 16.2127 9.69352C16.1217 9.65578 16.039 9.60046 15.9693 9.53073L8.99993 2.56041L2.03055 9.53073C1.88982 9.67146 1.69895 9.75052 1.49993 9.75052C1.30091 9.75052 1.11003 9.67146 0.969304 9.53073C0.828573 9.39 0.749512 9.19912 0.749512 9.0001C0.749512 8.80108 0.828573 8.61021 0.969304 8.46948L8.4693 0.969476C8.53896 0.899744 8.62168 0.844425 8.71272 0.806682C8.80377 0.768939 8.90137 0.749512 8.99993 0.749512C9.09849 0.749512 9.19608 0.768939 9.28713 0.806682C9.37818 0.844425 9.4609 0.899744 9.53055 0.969476L17.0306 8.46948C17.1003 8.53913 17.1556 8.62185 17.1933 8.7129C17.2311 8.80395 17.2505 8.90154 17.2505 9.0001C17.2505 9.09866 17.2311 9.19626 17.1933 9.28731C17.1556 9.37836 17.1003 9.46107 17.0306 9.53073Z"
          fill="white"
        />
      </svg>

    </div>
  </div>
</template>
<script>
//   import NavbarMobileNo from "../NavbarMobileNo/NavbarMobileNo.vue";
import Footer from "../../src/components/HomePage/Footer/Footer.vue";

export default {
  name: "AboutUs",
  components: {
    //   NavbarMobileNo,
    Footer,
  },
  props: {},
  data() {
    return {
      showScrollToTop: false,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showScrollToTop = window.scrollY > 200;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  }
};
</script>
<style scoped>
.about-us-v-2,
.about-us-v-2 * {
  box-sizing: border-box;
}
.about-us-v-2 {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}
.navbar-instance {
  background: #ffffff !important;
  flex-shrink: 0 !important;
  width: 1440px !important;
}
.hero-section {
  background: #ffffff;
  padding: 30px 0 30px 0;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: flex-start;
  justify-content: flex-start;
  /* align-self: stretch; */
  flex-shrink: 0;
  position: relative;
}
.frame-35835 {
  background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    linear-gradient(to left, #e7ebf0, #e7ebf0);
  border-radius: 24px;
  padding: 60px;
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25),
    -4px -4px 8px 0px rgba(255, 255, 255, 1);
}
.frame-35836 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.about-us {
  color: #26bbc2;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  align-self: stretch;
}
.bridging-gaps-unveiling-opportunities-actionable-global-business-intelligence-for-cross-border-trade {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 36px;
  line-height: 150%;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.rectangle-11 {
  background: #e2e2e2;
  border-radius: 17.75px;
  border-width: 1.48px;
  /* border-style: solid; */
  border-image: linear-gradient(
    -47.43deg,
    rgba(20, 45, 77, 0.53) 0%,
    rgba(20, 45, 77, 0) 77.91016697883606%
  );
  background-repeat: no-repeat;
  background-size: cover;
  border-image-slice: 1;
  flex-shrink: 0;
  width: 500px;
  height: 500px;
  position: relative;
  box-shadow: 0px 11.83px 17.75px 0px rgba(20, 45, 77, 0.1);
}
.frame-358362 {
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.rectangle-112 {
  background: #e2e2e2;
  border-width: 1.48px;
  background-color: white;
  border-image-slice: 1;
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rectangle-112-img {
  max-width: 100%;
  max-height: 500px;
  object-fit: cover;
  aspect-ratio: 1/1;
  box-shadow: 0px 11.83px 17.75px 0px rgba(20, 45, 77, 0.1);
  border-image: linear-gradient(
    -47.43deg,
    rgba(20, 45, 77, 0.53) 0%,
    rgba(20, 45, 77, 0) 77.91016697883606%
  );
  border-radius: 17.75px;
}
.our-story {
  color: #26bbc2;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  align-self: stretch;
}
.the-data-story-for-asia {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.more-than-a-decade-ago-we-embarked-on-a-mission-unlike-any-other-connecting-not-just-large-corporates-but-the-smallest-of-sm-es-in-emerging-countries-with-the-global-financial-ecosystem-faced-with-a-landscape-where-existing-solutions-failed-to-offer-the-breadth-and-depth-of-information-necessary-we-forged-our-path-credence-was-born-in-2022-out-of-a-need-for-comprehensive-insights-into-the-risks-associated-with-exports-and-imports-today-we-are-proud-to-be-the-trusted-partner-for-banks-corporates-of-various-sizes-providing-expedited-and-updated-risk-assessments-that-empower-cross-border-trade {
  color: #3e4855;
  text-align: justify;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: stretch;
}
.frame-35839 {
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-358363 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.stories-of-success {
  color: #26bbc2;
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  align-self: stretch;
}
.making-an-impact {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.our-work-speaks-through-the-successes-of-the-businesses-we-ve-partnered-with-from-sm-es-in-remote-corners-of-the-world-gaining-access-to-global-markets-to-major-banks-streamlining-their-risk-assessment-processes-our-impact-is-as-varied-as-it-is-profound-these-stories-our-badges-of-honor-exemplify-the-transformative-power-of-accessible-reliable-business-intelligence {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: stretch;
}
.frame-35837 {
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.our-mission {
  color: #26bbc2;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  align-self: stretch;
}
.accelerating-borderless-trade-for-everyone {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.at-credence-we-re-more-than-a-business-intelligence-company-we-re-a-catalyst-for-change-in-b-2-b-transactions-across-diverse-geographies-our-core-belief-a-democratized-world-of-trade-where-the-size-of-your-enterprise-or-its-location-doesn-t-dictate-your-success-whether-you-re-buying-selling-or-financing-we-re-here-to-ensure-you-make-the-right-decision-every-time-we-envision-a-world-where-every-business-irrespective-of-its-scale-or-geography-can-compete-on-a-level-playing-field {
  color: #3e4855;
  text-align: justify;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: stretch;
}
.frame-35838 {
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.our-team {
  color: #26bbc2;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  align-self: stretch;
}
.the-heart-behind-the-mission {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.in-hong-kong-india-and-bangladesh-our-journey-would-not-be-possible-without-our-dedicated-team-spanning-across-asia-our-team-comprises-individuals-passionate-about-breaking-down-the-barriers-to-global-trade-and-with-background-in-international-trade-and-finance-from-risk-analysts-to-customer-success-specialists-every-member-of-our-team-is-dedicated-to-empowering-our-clients-with-actionable-intelligence-we-believe-in-the-power-of-diversity-not-just-in-the-markets-we-serve-but-within-our-own-ranks-this-diversity-fuels-our-innovation-enabling-us-to-understand-and-meet-the-needs-of-a-varied-clientele-our-team-s-expertise-is-matched-only-by-their-dedication-to-our-mission-of-democratizing-global-trade-finance {
  color: #3e4855;
  text-align: justify;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: stretch;
}
.frame-35840 {
  border-radius: 24px;
  padding: 40px 0px 80px 0px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.join-us-on-this-journey {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  align-self: stretch;
}
.we-re-on-a-mission-to-redefine-the-landscape-of-global-trade-making-it-accessible-transparent-and-fair-for-all-if-you-re-ready-to-explore-how-credence-can-empower-your-business-contact-us-today-let-s-unveil-opportunities-together {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
  align-self: stretch;
}
.footer-instance {
  flex-shrink: 0 !important;
  width: 100% !important;
}
.BlogsBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  min-height: 200px;
  width: 100%;
  /* margin-top: 50px; */
}
.BlogsBanner_after {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 4rem 0;
  /* background: linear-gradient(90deg, #11365a63, #d3d3d399 50.31%, #ffffff00); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.BlogbannercontentBox {
  width: 90%;
  color: #26BBC2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 15%;
  background-color: #ffffffab;
  padding: 20px;
  border-radius: 16px;
}
.TitleTop {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  font-family: "Montserrat-Medium", sans-serif;
}
.Title {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.44;
}
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  background-color: #11365abd;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18);
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  z-index: 1000;
}
.scroll-to-top:hover {
  background-color: #11365a;
}
.scroll-to-top.show {
  display: block;
  gap: 20px;
}
@media only screen and (max-width: 767px) {
  .frame-358362 {
    flex-direction: column;
  }
  .TitleTop {
    font-size: large;
  }
  .bridging-gaps-unveiling-opportunities-actionable-global-business-intelligence-for-cross-border-trade {
    font-size: 14px;
  }
  .BlogsBanner_after {
    padding: 2rem 0 1rem 0;
  }
  .BlogsBanner {
    margin-top: 0;
  }
  .hero-section {
    gap: 20px;
  }
  .rectangle-112-img {
    max-height: 220px;
    width: 100%;
  }
  .our-story {
    font-size: 14px;
    text-align: center;
  }
  .the-data-story-for-asia {
    font-size: 16px;
    text-align: center;
  }
  .more-than-a-decade-ago-we-embarked-on-a-mission-unlike-any-other-connecting-not-just-large-corporates-but-the-smallest-of-sm-es-in-emerging-countries-with-the-global-financial-ecosystem-faced-with-a-landscape-where-existing-solutions-failed-to-offer-the-breadth-and-depth-of-information-necessary-we-forged-our-path-credence-was-born-in-2022-out-of-a-need-for-comprehensive-insights-into-the-risks-associated-with-exports-and-imports-today-we-are-proud-to-be-the-trusted-partner-for-banks-corporates-of-various-sizes-providing-expedited-and-updated-risk-assessments-that-empower-cross-border-trade {
    font-size: 12px;
    text-align: center;
  }
  .stories-of-success {
    font-size: 14px;
    text-align: center;
  }
  .making-an-impact {
    font-size: 16px;
    text-align: center;
  }
  .our-work-speaks-through-the-successes-of-the-businesses-we-ve-partnered-with-from-sm-es-in-remote-corners-of-the-world-gaining-access-to-global-markets-to-major-banks-streamlining-their-risk-assessment-processes-our-impact-is-as-varied-as-it-is-profound-these-stories-our-badges-of-honor-exemplify-the-transformative-power-of-accessible-reliable-business-intelligence {
    font-size: 12px;
    text-align: center;
  }
  .our-mission {
    font-size: 14px;
    text-align: center;
  }
  .accelerating-borderless-trade-for-everyone {
    font-size: 16px;
    text-align: center;
  }
  .at-credence-we-re-more-than-a-business-intelligence-company-we-re-a-catalyst-for-change-in-b-2-b-transactions-across-diverse-geographies-our-core-belief-a-democratized-world-of-trade-where-the-size-of-your-enterprise-or-its-location-doesn-t-dictate-your-success-whether-you-re-buying-selling-or-financing-we-re-here-to-ensure-you-make-the-right-decision-every-time-we-envision-a-world-where-every-business-irrespective-of-its-scale-or-geography-can-compete-on-a-level-playing-field {
    font-size: 12px;
    text-align: center;
  }
  .frame-35837{
    flex-direction: column;
  }
  .our-team {
    font-size: 14px;
    text-align: center;
  }
  .the-heart-behind-the-mission {
    font-size: 16px;
    text-align: center;
  }
  .in-hong-kong-india-and-bangladesh-our-journey-would-not-be-possible-without-our-dedicated-team-spanning-across-asia-our-team-comprises-individuals-passionate-about-breaking-down-the-barriers-to-global-trade-and-with-background-in-international-trade-and-finance-from-risk-analysts-to-customer-success-specialists-every-member-of-our-team-is-dedicated-to-empowering-our-clients-with-actionable-intelligence-we-believe-in-the-power-of-diversity-not-just-in-the-markets-we-serve-but-within-our-own-ranks-this-diversity-fuels-our-innovation-enabling-us-to-understand-and-meet-the-needs-of-a-varied-clientele-our-team-s-expertise-is-matched-only-by-their-dedication-to-our-mission-of-democratizing-global-trade-finance {
    font-size: 12px;
    text-align: center;
  }
  .frame-35838{
    flex-direction: column;
  }
  .join-us-on-this-journey {
    font-size: 16px;
    text-align: center;
  }
  .we-re-on-a-mission-to-redefine-the-landscape-of-global-trade-making-it-accessible-transparent-and-fair-for-all-if-you-re-ready-to-explore-how-credence-can-empower-your-business-contact-us-today-let-s-unveil-opportunities-together {
    font-size: 12px;
    text-align: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .hero-section { 
    width: 90%;
  }
  .TitleTop {
    font-size: large;
  }
  .bridging-gaps-unveiling-opportunities-actionable-global-business-intelligence-for-cross-border-trade {
    font-size: larger;
  }
  .BlogsBanner_after {
    padding: 3rem 0;
  }
  .rectangle-112-img {
    max-height: 220px;
  }
  .our-story {
    font-size: large;
  }
  .the-data-story-for-asia {
    font-size: larger;
  }
  .more-than-a-decade-ago-we-embarked-on-a-mission-unlike-any-other-connecting-not-just-large-corporates-but-the-smallest-of-sm-es-in-emerging-countries-with-the-global-financial-ecosystem-faced-with-a-landscape-where-existing-solutions-failed-to-offer-the-breadth-and-depth-of-information-necessary-we-forged-our-path-credence-was-born-in-2022-out-of-a-need-for-comprehensive-insights-into-the-risks-associated-with-exports-and-imports-today-we-are-proud-to-be-the-trusted-partner-for-banks-corporates-of-various-sizes-providing-expedited-and-updated-risk-assessments-that-empower-cross-border-trade {
    font-size: 14px;
    text-align: justify;
  }
  .stories-of-success {
    font-size: large;
  }
  .making-an-impact {
    font-size: larger;
  }
  .our-work-speaks-through-the-successes-of-the-businesses-we-ve-partnered-with-from-sm-es-in-remote-corners-of-the-world-gaining-access-to-global-markets-to-major-banks-streamlining-their-risk-assessment-processes-our-impact-is-as-varied-as-it-is-profound-these-stories-our-badges-of-honor-exemplify-the-transformative-power-of-accessible-reliable-business-intelligence {
    font-size: 14px;
  }
  .our-mission {
    font-size: large;
  }
  .accelerating-borderless-trade-for-everyone {
    font-size: larger;
  }
  .at-credence-we-re-more-than-a-business-intelligence-company-we-re-a-catalyst-for-change-in-b-2-b-transactions-across-diverse-geographies-our-core-belief-a-democratized-world-of-trade-where-the-size-of-your-enterprise-or-its-location-doesn-t-dictate-your-success-whether-you-re-buying-selling-or-financing-we-re-here-to-ensure-you-make-the-right-decision-every-time-we-envision-a-world-where-every-business-irrespective-of-its-scale-or-geography-can-compete-on-a-level-playing-field {
    font-size: 14px;
  }
  .our-team {
    font-size: large;
  }
  .the-heart-behind-the-mission {
    font-size: larger;
  }
  .in-hong-kong-india-and-bangladesh-our-journey-would-not-be-possible-without-our-dedicated-team-spanning-across-asia-our-team-comprises-individuals-passionate-about-breaking-down-the-barriers-to-global-trade-and-with-background-in-international-trade-and-finance-from-risk-analysts-to-customer-success-specialists-every-member-of-our-team-is-dedicated-to-empowering-our-clients-with-actionable-intelligence-we-believe-in-the-power-of-diversity-not-just-in-the-markets-we-serve-but-within-our-own-ranks-this-diversity-fuels-our-innovation-enabling-us-to-understand-and-meet-the-needs-of-a-varied-clientele-our-team-s-expertise-is-matched-only-by-their-dedication-to-our-mission-of-democratizing-global-trade-finance {
    font-size: 14px;
  }
  .join-us-on-this-journey {
    font-size: larger;
  }
  .we-re-on-a-mission-to-redefine-the-landscape-of-global-trade-making-it-accessible-transparent-and-fair-for-all-if-you-re-ready-to-explore-how-credence-can-empower-your-business-contact-us-today-let-s-unveil-opportunities-together {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1156px) {}
</style>
