<template>
  <div
    :class="[
      'table-container',
      {
        'logged-in': !isMobile,
        'not-logged-in': isMobile,
        collapsed: this.$root.isCollapsed && !isMobile,
      },
    ]"
  >
    <div class="plan-info">
      <div class="heading">
        <div class="back-button" @click="goBack">
          <i class="fa-solid fa-angle-left" style="margin-right: 10px"></i>
          Back
        </div>
        <div class="credit-plan-title">Assign Credit plan to Entity</div>
      </div>
      <form class="selection-form">
        <div class="selection-group">
          <label for="entitySelect" class="selection-label"
            >Select an Entity</label
          >
          <select
            id="entitySelect"
            class="select-input select-wrapper"
            v-model="bankName"
            aria-label="Select entity"
          >
            <option value="" disabled>Select Entity</option>
            <option
              v-for="(bank, index) in banksList"
              :key="index"
              :value="bank"
            >
              {{ bank.name }}
            </option>
          </select>
        </div>

        <div class="selection-group">
          <label for="planSelect" class="selection-label"
            >Select a Credit Plan Name</label
          >
          <select
            id="planSelect"
            class="select-input select-wrapper"
            v-model="selectedCreditPlan"
            aria-label="Select credit plan"
          >
            <option value="" disabled>Select Credit Plan</option>
            <option
              v-for="(plan, index) in creditPlanList"
              :key="index"
              :value="plan"
            >
              {{ plan.name }}
            </option>
          </select>
        </div>

        <div class="selection-group">
          <label for="conversionRate" class="selection-label"
            >Conversion Rate</label
          >
          <input
            type="number"
            id="conversionRate"
            class="rate-input no-arrows"
            placeholder="Enter Conversion Rate"
            aria-label="Enter conversion rate"
            v-model="conversionRate"
          />
        </div>

        <div class="selection-group">
          <label for="conversionRate" class="selection-label"
            >Purchased Credits</label
          >
          <input
            type="number"
            id="creditsTobePurchased"
            class="rate-input no-arrows"
            placeholder="Enter purchased credits"
            aria-label="Enter purchased credits"
            v-model="creditsTobePurchased"
          />
        </div>
      </form>
      <section class="subscription-section">
        <div class="subscription-title">Type of Subscription</div>
        <p class="subscription-text">
          {{ selectedCreditPlan.subscriptionType || "-" }}
        </p>
      </section>
      <section class="subscription-section">
        <div class="subscription-title">Validity</div>
        <p class="subscription-text">
          {{
            selectedCreditPlan.subscriptionValidityDays
              ? `${selectedCreditPlan.subscriptionValidityDays} days`
              : "-"
          }}
        </p>
      </section>
      <section class="subscription-section">
        <div class="subscription-title">Credit Consumption</div>
        <ul class="credit-info" v-if="selectedCreditPlan.description && selectedCreditPlan.description.length">
          <li
            class="credit-text"
            v-for="(item, index) in selectedCreditPlan.description"
            :key="index"
          >
            <span>&#8226;</span> {{ item }}
          </li>
        </ul>
        <p v-else class="credit-text">-</p>
      </section>
      <div class="action-buttons">
        <button
          type="button"
          class="action-button cancel-button"
          @click="handleCancel"
          tabindex="0"
          aria-label="Cancel action"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="action-button submit-button"
          :style="{ opacity: !isFormValid || isSubmitting ? 0.5 : 1 }"
          tabindex="0"
          aria-label="Submit form"
          :disabled="!isFormValid || isSubmitting"
          @click="handleSubmit"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import Toastify from "@/components/Toastify.vue";
import {
  GetBanksList,
  GetCreditPlans,
  AssignCreditPlan,
  CurrentCreditPlan,
} from "@/api/APIs";

export default {
  name: "CreditPlanAssignment",
  components: {
    Toastify,
  },
  data() {
    return {
      isMobile: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      banksList: null,
      bankName: "",
      creditPlanList: null,
      selectedCreditPlan: "",
      conversionRate: "",
      isSubmitting: false,
      creditBalance: 0,
      creditsTobePurchased: 0,
    };
  },
  computed: {
    isFormValid() {
      return this.bankName && this.selectedCreditPlan && this.conversionRate;
    },
  },
  mounted() {
    this.BanksList();
    this.PlansList();
  },
  watch: {
    bankName(newValue) {
      if (newValue) {
        this.GetCurrentCreditPlan();
      }
    },
  },
  methods: {
    async BanksList() {
      try {
        const response = await GetBanksList("");
        if (response) {
          this.banksList = response?.allEntitiesResults;
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async PlansList() {
      try {
        const response = await GetCreditPlans();
        if (response?.message === "SUCCESS") {
          this.creditPlanList = response?.data;
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    goBack() {
      this.$router.push("/banks-&-hq");
    },
    async GetCurrentCreditPlan() {
      try {
        let payload = {
          entityId: this.bankName.id,
        };
        const response = await CurrentCreditPlan(payload);
        if (response.message == "SUCCESS") {
          this.creditBalance = response.creditBalance;
          this.conversionRate = response.creditsToUsd;
          const currentPlan = this.creditPlanList.find(
            (plan) => plan.id === response.creditPlan.id
          );
          if (currentPlan) {
            this.selectedCreditPlan = currentPlan;
          }
        } else if (
          response.message ==
          "No credit plan is available for the provided entity ID."
        ) {
          this.selectedCreditPlan = "";
          this.conversionRate = "";
          this.creditsTobePurchased = 0;
          return;
        } else {
          return this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      }
    },
    async handleSubmit() {
      this.isSubmitting = true;
      try {
        let payload = {
          creditPlan: this.selectedCreditPlan.id,
          entityId: this.bankName.id,
          creditBalance: this.creditBalance,
          purchasedCredits: this.creditsTobePurchased,
          creditsToUsd: this.conversionRate,
        };
        const response = await AssignCreditPlan(payload);
        if (
          response === "Credit plan updated successfully" ||
          response === "Credit plan mapped successfully"
        ) {
          this.$refs.toastContainer.addToast(response, { type: "success" });
          this.resetFormState();
        } else {
          return this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.$refs.toastContainer.addToast(error.response.data, {
            type: "error",
          });
        }
        if (
          error.response &&
          error.response.status !== 403 &&
          error.response &&
          error.response.status !== 400
        ) {
          this.$refs.toastContainer.addToast(
            `Something went wrong! Please try again later.`,
            { type: "error" }
          );
        }
      } finally {
        this.isSubmitting = false;
      }
    },
    resetFormState() {
      this.selectedCreditPlan = "";
      this.conversionRate = "";
      this.creditsTobePurchased = 0;
      this.bankName = "";
      this.creditBalance = 0;
    },
    handleCancel() {
      this.resetFormState();
    },
  },
};
</script>
<style scoped>
.table-container,
.table-container * {
  box-sizing: border-box;
}
.table-container {
  padding-top: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 100vh;
  background: white;
}
.logged-in {
  margin-left: 18vw;
  padding-top: 55px;
  transition: margin-left 0.3s ease;
}
.logged-in.collapsed {
  margin-left: 60px;
}
.not-logged-in {
  width: 100%;
}
.plan-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: -webkit-fill-available;
  padding: 20px 40px;
  gap: 40px;
  height: 100%;
}
.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}
.credit-plan-title {
  align-self: stretch;
  color: rgba(17, 54, 90, 1);
  letter-spacing: -0.24px;
  font: 600 24px/1.2 Montserrat, sans-serif;
  text-align: left;
}
.selection-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: -webkit-fill-available;
  align-items: start;
  gap: 40px;
  font-family: Montserrat, sans-serif;
  color: rgba(17, 54, 90, 1);
  justify-content: start;
  flex-wrap: wrap;
}

.selection-group {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  flex: 1;
  flex-basis: 0%;
}
.selection-label {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}

.select-wrapper {
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  margin-top: 8px;
  min-height: 52px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border: 1px solid rgba(17, 54, 90, 0.4);
}

.select-input {
  background: none;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 28px;
  width: 100%;
  outline: none;
  font-family: inherit;
  color: inherit;
}
.rate-input {
  border-radius: 16px;
  background-color: #fff;
  margin-top: 8px;
  min-height: 52px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 28px;
  padding: 12px 24px;
  border: 1px solid rgba(17, 54, 90, 0.4);
  font-family: inherit;
  color: inherit;
}
.subscription-section {
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  color: rgba(17, 54, 90, 1);
}

.subscription-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  text-align: left;
}

.subscription-text {
  font-size: 16px;
  font-weight: 500;
  margin: 8px 0 0;
  text-align: left;
}
.credit-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.credit-text {
  color: rgba(17, 54, 90, 1);
  font: 500 16px/28px Montserrat, sans-serif;
  margin: 0;
  text-align: left;
}
.action-buttons {
  display: flex;
  align-self: stretch;
  align-items: center;
  gap: 19px;
  justify-content: flex-end;
  flex-wrap: wrap;
  font: 600 16px/28px Montserrat, sans-serif;
}

.action-button {
  align-self: stretch;
  min-width: 18px;
  border-radius: 10px;
  margin: auto 0;
  cursor: pointer;
  border: none;
  font: inherit;
}

.cancel-button {
  color: var(--Dark-Blue, #11365a);
  border: 2px solid var(--Dark-Blue, #11365a);
  background: transparent;
}

.submit-button {
  color: #fff;
  background: var(--Dark-Blue, #11365a);
}
.back-button {
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #11365a;
  padding: 10px 0;
  border-radius: 14px;
  opacity: 0.7;
}
.back-button:hover {
  opacity: 1;
}
.no-arrows {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}
.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .table-container {
    padding-top: 0;
  }
  .selection-form {
    grid-template-columns: repeat(1, 1fr);
  }
  .plan-info {
    gap: 25px;
    padding: 20px;
  }
  .credit-plan-title {
    font-size: 20px;
  }
  .select-input,
  .rate-input {
    min-height: 45px;
    padding: 10px 24px;
  }
  .rate-input {
    line-height: 22px;
  }
  .selection-label,
  .subscription-title {
    font-size: 16px;
  }
  .selection-form {
    gap: 25px;
  }
  .action-buttons {
    flex-wrap: nowrap;
  }
  .action-button {
    width: -webkit-fill-available;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
}
@media only screen and (min-width: 1156px) {
}
</style>
