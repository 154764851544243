<template>
  <div
    class="bg-b-lue"
    v-on:click.self="close()"
  >
    <div class="frame-35628">
      <img
        class="referral-gif"
        :src="this.$root.getCDNlink('assets/Referral.gif')"
        alt="Credence referral program"
      />
      <div class="referral-rewards-title1">Welcome to Credence!</div>
      <div class="referral-rewards-title2">
        You've earned {{this.$root.referralCredits}} to use on your future orders
      </div>
      <div
          class="button"
          style="
            background-color: white;
            border: 2px solid #11365a;
            padding: 14px 0px;
          "
          v-on:click.prevent="close()"
        >
          <div class="button-text">Close</div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReferralCredits",
  components: {
  },
  props: {},
  data() {
    return {
    };
  },
  async mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: inline-flex;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 40px 40px 10px rgba(17, 54, 90, 0.2);
}
.referral-gif {
  width: 35vw;
}
.referral-rewards-title1 {
  color: var(--Dark-Blue, #11365a);
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.48px;
  align-self: stretch;
}
.referral-rewards-title2 {
  color: var(--Dark-Blue, #11365a);
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.36px;
  /* text-transform: capitalize; */
  align-self: stretch;
  opacity: 0.9;
}
.button {
  border-radius: 13px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 150px;
  width: 100%;
  cursor: pointer;
}
.button-text {
  color: #11365A;
  text-align: center;
  font-family: "NunitoSans-SemiBold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}
@media only screen and (max-width: 767px) {
}
</style>
